import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import cn from 'classnames';
import contexts from '../../context/contexts';
import Event from './Event';
import EditableEvent from './EditableEvent';
import EditEvent from './EditEvent';
import Loading from '../../widgets/Loading';
import './Calendar.less';

const {
	useStore: useEventStore,
	useDispatch: useEventDispatch,
	makeDispatcher: makeEventDispatcher
} = contexts.events;

const {
	useStore: useLoginStore
} = contexts.login;

const debug = false;

export default function Calendar({ props }) {
	const eventDispatch = makeEventDispatcher(useEventDispatch());
	const { events } = useEventStore();
	const navigate = useNavigate();

	const credentials = sessionStorage.getItem('credentials');
	const authenticated = !!credentials;

	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [showConfirmDeleteAll, setShowConfirmDeleteAll] = useState(false);
	const handleClose = () => setShowConfirmDelete(false);
	const handleDeleteAllClose = () => setShowConfirmDeleteAll(false);
	const [updating, setIsUpdating] = useState(null);
	const [editEvent, showEditDialog] = useState(false);

	const log = debug ? console.log : () => {};

	useEffect(() => {
		log('use-effect-1');
		if (authenticated) {
			eventDispatch({ type: 'FETCH_EVENTS_FULL' });
		} else {
			eventDispatch({ type: 'FETCH_EVENTS' });
		}
	}, []); // the [] is for when mounting only

	useEffect(() => {
		log('use-effect-2');
		setIsUpdating(null);
	}, [events && events.data]);

	useEffect(() => {
		log('use-effect-3');
		if (events.staleEvents) {
			if (authenticated) {
				eventDispatch({ type: 'FETCH_EVENTS_FULL' });
			} else {
				eventDispatch({ type: 'FETCH_EVENTS' });
			}
		}
	}, [events.staleEvents === true])

	function onConvert(event, type) {
		const newEvent = {
			location: 'teachers-club',
			date: event.date,
			title: event.title,
			summary: 'Everyone with an interest in traditional singing (in any language) is welcome! Just come along and enjoy the singing, and of course the company, and maybe you might like to sing a traditional song yourself. You are more than welcome! Tá míle fáilte romhat! We would especially welcome more new singers, more women singers, more younger singers agus ar ndóigh fáilte speisialta roimh Ghaeilge!',
			description: '',
			fromTime: '8pm',
			toTime: '11pm',
			tags: event.tags,
			flags: event.flags
		};
		if (type === 'as-gailge') {
			newEvent.title = 'Special guest (As Ghaeilge)';
			newEvent.tags = [
				'as-gaeilge'
			];
		} else if (type === 'special-guest') {
			newEvent.title = 'Special guest';
			newEvent.tags = [
				'special-guest'
			];
		} else if (type === 'zoom') {
			newEvent.title = 'Open Singing Session';
			newEvent.tags = [
				'open-singing-session'
			];
		} else if (type === 'none') {
			newEvent.location = '';
			newEvent.title = 'Closed (no session)';
			newEvent.summary = 'No session - The Góilín is closed.'
			newEvent.tags = [
				'no-club'
			];
		}
		setIsUpdating(event.date);

		log('updating event', newEvent);
		eventDispatch({
			type: 'UPDATE_EVENT',
			payload: {
				credentials,
				event: newEvent
			}
		});
	}

	function handleDeleteEvent(event) {
		log('deleting event', event);
		eventDispatch({
			type: 'DELETE_EVENT',
			payload: {
				credentials,
				event
			}
		});
		setShowConfirmDelete(false);
	}

	function handleDeleteAll() {
		log('delete all events');
		eventDispatch({
			type: 'DELETE_ALL_EVENTS',
			payload: {
				credentials
			}
		});
		setShowConfirmDeleteAll(false);
	}

	function showConfirmDeleteModal(event) {
		return (
			<Modal show={!!event} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						Delete <b>{event.dateDay} {event.dateMonthAbbrev}</b>?
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to delete?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button variant="danger" onClick={() => handleDeleteEvent(event)}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	function showConfirmDeleteAllModal() {
		return (
			<Modal show={!!showConfirmDeleteAll} onHide={handleDeleteAllClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						Regenerate event calendar?
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to delete all events and regenerate the caledar?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleDeleteAllClose}>
						Cancel
					</Button>
					<Button variant="danger" onClick={() => handleDeleteAll()}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	log('calendar', {
		updating: !!updating,
		finishedLoading: !!(events && events.finishedLoading),
		staleEvents: !!(events && events.staleEvents),
		count: (events && events.data && events.data.length) || 0,
		authenticated,
		events
	});

	let all;
	if (events.finishedLoading && !events.data.length) {
		all = (
			<div className="box-content box">
				No events.
			</div>
		);
	} else if (!events.finishedLoading) {
		all = <Loading title="Loading events..." />;
	} else {
		all = events.data.map(event => {
			if (authenticated) {
				const isUpdating = event.date === updating;
				return (
					<div key={event.date} className={cn('box-content', 'box', { updating: isUpdating })}>
						<EditableEvent
							event={event}
							onConvert={onConvert}
							onDelete={() => setShowConfirmDelete(event)}
							onEdit={() => {
								navigate(`/calendar/${event.date}/edit`)
							}}
						/>
					</div>
				);
			} else {
				return (
					<div key={event.date} className="box-content box">
						<Event image event={event} />
					</div>
				);
			}
		});
	}

	return (
		<div className="calendar">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					An Góilín Traditional Singers - Calendar
				</title>
			</Helmet>
			<h1>Event calendar</h1>
			{ authenticated && (
				<div className="box-content">
					<Button variant="danger" onClick={() => setShowConfirmDeleteAll(true)}>
						<span className="bi-trash-fill"></span>
						Delete all and regenerate
					</Button>
				</div>
			)}
			<div>
				{all}
			</div>

			{showConfirmDeleteModal(showConfirmDelete)}
			{showConfirmDeleteAllModal()}

			<Modal size="lg" show={!!editEvent} onHide={() => {}}>
				<Modal.Header closeButton>
					<Modal.Title>
						Editing <b>{editEvent.dateDay} {editEvent.dateMonthAbbrev}</b>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditEvent event={editEvent} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => showEditDialog(false)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => showEditDialog(false)}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
