import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import gclef from '../../../images/angoilin.svg';
import './About.less';

const About = () => {
	return (
		<div className="about">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					An Góilín Traditional Singers - About
				</title>
			</Helmet>
			<h1>About An Góilín Traditional Singers</h1>
			<div className="box-content box">
				<img src={gclef} className="gclef" />
				<p>
				The gathering of Góilín Traditional Singers has been in existence since 1979.
				It was started by Tim Dennehy and his brother-in-law Dónal de Barra to give an
				opportunity to people who like to sing and to listen to traditional songs.
				</p>
				<p>
				We have a motto which says: "There is no standard set" (not as regards the
				singing anyway!) We do however encourage a very high standard in listening.
				To this end, we respectfully ask that if someone is singing, reciting or
				performing in any way, that you simply give your attention to the
				performance.
				</p>
				<p>
				We do not have a ban on musical instruments, but as the emphasis is on
				traditional singing, we prefer that if you choose to play a musical instrument
				that you also sing. Anyone, in fact, who has an interest in listening to,
				or singing traditional songs will find that they are "as welcome as the
				flowers in May".
				</p>
				<p>
				We meet regularly every Friday night (with some exceptions) and break for
				the Summer.  Our calendar of events can be found on
				our <Link to="/calendar">calendar page</Link> and on
				our <Link to="https://m.facebook.com/AnGoilin">Facebook page</Link>.
				</p>
				<p>
				We ask that everyone makes a minimal contribution towards the running of
				the session and normally everyone (including the organisers) contributes
				€3.00 per session. At present this contribution can be made by using the
				Donate button on our website.
				</p>
				<p>
				Although we are sometimes referred to as "An Góilín Singers' Club" we are
				{" "}<em>not</em>, in fact, a "club".  There is no "joining the club" nor "becoming a member".
				Although our venue is known
				as <a href="https://clubnamuinteoiri.com">Club na Múinteoirí / The Teachers' Club</a> at
				<a href="https://goo.gl/maps/PAEz26NZcKty4rcJ7" target="_blank">36 Parnell Square, Dublin D01 T6V6</a> from 8pm – 11pm.  You do <em>not</em> have
				to be a member of "The Teachers' Club", nor do you have to be a teacher to
				come to the session.
				</p>
				<p>
				Everyone with an interest in traditional singing (in any language) is
				welcome! Just come along and enjoy the singing, and of course the company,
				and maybe you might like to sing a traditional song yourself. You are more
				than welcome! Tá míle fáilte romhat!
				</p>
				<p>
				We would especially welcome more new singers, more women singers, more
				younger singers agus ar ndóigh fáilte speisialta roimh Ghaeilge!
				</p>
			</div>
		</div>
	);
};

export default About;
