import React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { NavMenu, Links } from './NavMenu';
import './MainLayout.less';

export default class HomeLayout extends React.Component {
	render() {
		const links = Links.map((a) => <a key={a.name} href={a.url}>{a.name}</a>);

		return (
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>
						An Góilín Traditional Singers
					</title>
				</Helmet>
				<div className="main-links">
					{links}
				</div>
				<NavMenu />
				<div className="home-content">
					<Outlet />
				</div>
			</div>
		);
	}
}
