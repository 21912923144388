import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import DonateButton from 'widgets/DonateButton';
import { LinkContainer } from 'react-router-bootstrap';

export const Links = [
	{ name: 'Downloads', url: '/downloads' },
	{ name: 'Photos', url: '/photos' },
	{ name: 'Links', url: '/links' },
	{ name: 'Login', url: '/login' },
	{ name: 'About', url: '/about' },
	{ name: 'Calendar', url: '/calendar' },
	// { name: 'Shop', url: '/shop' },
	// { name: 'Songs', url: '/songs' }
];

export const NavMenu = ({ authenticated, expand = 'lg', onLogout }) => {
	return (
		<Navbar expand={expand} collapseOnSelect>
			<Navbar.Brand className="nav-link" href="/">
				<div className="banner">
					<h2>An Góilín</h2>
					<h3 className="text-2">Traditional Singers</h3>
				</div>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
				<Nav activeKey="/" className="justify-content-center">
					<NavDropdown title="An Góilín" id="basic-nav-dropdown" className="justify-content-end">
						{<LinkContainer to="/downloads">
							<Nav.Link>Downloads</Nav.Link>
						</LinkContainer>}
						<LinkContainer to="/photos">
							<Nav.Link>Photos</Nav.Link>
						</LinkContainer>
						{<LinkContainer to="/links">
							<Nav.Link>Links</Nav.Link>
						</LinkContainer>}
						<LinkContainer to="/song-database">
							<Nav.Link>Song Project</Nav.Link>
						</LinkContainer>
						<LinkContainer to="/login">
							<Nav.Link>Login</Nav.Link>
						</LinkContainer>
					</NavDropdown>
					<Nav.Item>
						<LinkContainer to="/about">
							<Nav.Link>About</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					<Nav.Item>
						<LinkContainer to="/calendar">
							<Nav.Link>Calendar</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					{/*<Nav.Item>
						<LinkContainer to="/shop">
							<Nav.Link>Shop</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					*/}
					{authenticated && (
					<Nav.Item>
						<a className="nav-link" href="#" onClick={onLogout}>Logout</a>
					</Nav.Item>
					)}
					<Nav.Item>
						<DonateButton />
					</Nav.Item>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};
