import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import GlobalContext from './context/GlobalContext';
import './styles/app.less';
import './styles/app.scss';

/*
global state research:
https://medium.com/@martin.crabtree/react-creating-a-redux-like-global-state-with-the-usecontext-and-usereducer-hooks-89aa2b27dbc5
https://dev.to/ankitjena/ciao-redux-using-react-hooks-and-context-effectively-398j



Type: (guest | club)

Title (!club): [______________________]
[ ] Custom title

Description: [________________________]
[ ] Custom description

Summary: [______________________]
[ ] Custom summary

Description:
[ ] Include donation

Tags:
[ ] Guest
[ ] As Gaeilge
[ ] Online
*/

const root = createRoot(document.getElementById('root'));
root.render(
	<GlobalContext>
		<App />
	</GlobalContext>
);
