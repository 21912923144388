import React from 'react';
import { Outlet } from 'react-router-dom';
import contexts from '../context/contexts';
import { NavMenu } from './NavMenu';
import './MainLayout.less';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.login;

const MainLayout = () => {
	const credentials = sessionStorage.getItem('credentials');
	const authenticated = !!credentials;
	const dispatch = makeDispatcher(useDispatch());

	function onLogout(ev) {
		ev.preventDefault();

		dispatch({
			type: 'FETCH_LOGOUT',
			payload: { credentials }
		});
	}

	return (
		<div>
			<NavMenu authenticated={authenticated} onLogout={onLogout} />
			<div className="main-content">
				<Outlet />
			</div>
		</div>
	);
};

export default MainLayout;
